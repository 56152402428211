import { request } from "./request.js";
export function Index() {
  return request({
    method: "post",
    url: "/Api/index",
  });
}
export function GoodsIndex() {
  return request({
    method: "post",
    url: "/Api/goods_index",
  });
}
export function Fxcxgl1() {
  return request({
    method: "post",
    url: "/Api/fxcxgl1",
  });
}
export function Fxcxgl2(data) {
  return request({
    method: "post",
    url: "/Api/Fxcxgl2",
    data: data,
  });
}
export function Fxcxgl3(data) {
  return request({
    method: "post",
    url: "/Api/Fxcxgl3",
    data: data,
  });
}
export function Fxcxgl4(data) {
  return request({
    method: "post",
    url: "/Api/Fxcxgl4",
    data: data,
  });
}
// 设置-设置机床
export function Fxsz1() {
  return request({
    method: "post",
    url: "/Api/Fxsz1",
  });
}

// 设置-设置机床
export function Fxsz2(data = {}) {
  return request({
    method: "post",
    url: "/Api/Fxsz2",
    data: data,
  });
}

export function Fxsz3(data) {
  return request({
    method: "post",
    url: "/Api/Fxsz3",
    data,
  });
}
// 设置-设置机床-添加
export function Fxsz4(data) {
  return request({
    method: "post",
    url: "/Api/Fxsz4",
    data,
  });
}
// 登陆
export function Fxlogin(data) {
  return request({
    method: "post",
    url: "/Api/Fxlogin",
    data,
  });
}
// 查询user数据
export function Fxuser1() {
  return request({
    method: "post",
    url: "/Api/Fxuser1",
  });
}
// 修改user数据
export function Fxuser2(data) {
  return request({
    method: "post",
    url: "/Api/Fxuser2",
    data,
  });
}
// 修改密码
export function Fxuser3(data) {
  return request({
    method: "post",
    url: "/Api/Fxuser3",
    data,
  });
}
// 枫逍宏程序1001
export function Fxhcx1001(data) {
  return request({
    method: "post",
    url: "/Api/Fxhcx1001",
    data,
  });
}
// 枫逍宏程序1002
export function Fxhcx1002(data) {
  return request({
    method: "post",
    url: "/Api/Fxhcx1002",
    data,
  });
}
// 枫逍宏程序2001
export function Fxhcx2001(data) {
  return request({
    method: "post",
    url: "/Api/Fxhcx2001",
    data,
  });
}

// 枫逍宏程序2002
export function Fxhcx2002(data) {
  return request({
    method: "post",
    url: "/Api/Fxhcx2002",
    data,
  });
}
// 枫逍宏程序1003
export function Fxhcx1003(data) {
  return request({
    method: "post",
    url: "/Api/Fxhcx1003",
    data,
 
  });
}
// 枫逍宏程序2003
export function Fxhcx2003(data) {
  return request({
    method: "post",
    url: "/Api/Fxhcx2003",
    data,
 
  });
}
// 枫逍工具箱-圆周孔计算   Fxgjx2
export function Fxgjx2(data) {
  return request({
    method: "post",
    url: "/Api/Fxgjx2",
    data,
 
  });
}