import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "枫逍CNC-机床程序管理系统，加工宏程序自动生成，机加报价系统",
    },
  },
  {
    path: "/FXCXGL1",
    name: "FXCXGL1",
    component: () => import("../views/FXCXGL/FXCXGL1.vue"),
    meta: {
      title: "程序列表管理",
    },
  },
  {
    path: "/FXCXGL2/:cxname",
    name: "FXCXGL2",
    component: () => import("../views/FXCXGL/FXCXGL2.vue"),
    meta: {
      title: "程序单详情",
    },
  },
  {
    path: "/FXGJX1",
    name: "FXGJX1",

    component: () => import("../views/FXGJX/FXGJX1.vue"),
    meta: {
      title: "切削参数",
    },
  },
  {
    path: "/FXGJX2",
    name: "FXGJX2",

    component: () => import("../views/FXGJX/FXGJX2.vue"),
    meta: {
      title: "圆周孔位计算",
    },
  },
  {
    path: "/FXHCX1",
    name: "FXHCX1",

    component: () => import("../views/FXHCX/FXHCX1.vue"),
    meta: {
      title: "宏程序-西门子系统",
    },
  },
  {
    path: "/FXHCX1001",
    name: "FXHCX1001",

    component: () => import("../views/FXHCX/FXHCX1001.vue"),
    meta: {
      title: "西门子宏-往复层切铣平面",
    },
  },
  {
    path: "/FXHCX1002",
    name: "FXHCX1002",

    component: () => import("../views/FXHCX/FXHCX1002.vue"),
    meta: {
      title: "西门子宏-单向层切铣平面",
    },
  },
  {
    path: "/FXHCX1003",
    name: "FXHCX1003",

    component: () => import("../views/FXHCX/FXHCX1003.vue"),
    meta: {
      title: "西门子宏-螺旋铣孔",
    },
  },

  {
    path: "/FXHCX2",
    name: "FXHCX2",

    component: () => import("../views/FXHCX/FXHCX2.vue"),
    meta: {
      title: "宏程序-法兰克系统",
    },
  },
  {
    path: "/FXHCX2001",
    name: "FXHCX2001",

    component: () => import("../views/FXHCX/FXHCX2001.vue"),
    meta: {
      title: "法兰克宏-往复层切铣平面", 
    },
  },
  {
    path: "/FXHCX2002",
    name: "FXHCX2002",

    component: () => import("../views/FXHCX/FXHCX2002.vue"),
    meta: {
      title: "法兰克宏-单向层切铣平面", 
    },
  },
  {
    path: "/FXHCX2003",
    name: "FXHCX2003",

    component: () => import("../views/FXHCX/FXHCX2003.vue"),
    meta: {
      title: "法兰克宏-螺旋铣孔",
    },
  },
  {
    path: "/FXHCX3",
    name: "FXHCX3",

    component: () => import("../views/FXHCX/FXHCX3.vue"),
    meta: {
      title: "宏程序-三菱系统",
    },
  },
  {
    path: "/FXHCX3001",
    name: "FXHCX3001",

    component: () => import("../views/FXHCX/FXHCX3001.vue"),
    meta: {
      title: "三菱宏-往复层切铣平面",
    },
  },
  {
    path: "/FXHCX3002",
    name: "FXHCX3002",

    component: () => import("../views/FXHCX/FXHCX3002.vue"),
    meta: {
      title: "三菱宏-单向层切铣平面",
    },
  },
  {
    path: "/FXHCX4",
    name: "FXHCX4",

    component: () => import("../views/FXHCX/FXHCX4.vue"),
    meta: {
      title: "宏程序-其他系统",
    },
  },
  {
    path: "/FXSZ1",
    name: "FXSZ1",

    component: () => import("../views/FXSZ/FXSZ1.vue"),
    meta: {
      title: "机床设置",
    },
  },
  {
    path: "/FxLogin",
    name: "FxLogin",

    component: () => import("../views/Login/FxLogin.vue"),
    meta: {
      title: "用户登陆",
    },
  },
  {
    path: "/FxUser",
    name: "FxUser",

    component: () => import("../views/Login/FxUser.vue"),
    meta: {
      title: "系统设置",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
// 动态更改标题
router.beforeEach((to) => {
  document.title = to.meta.title;
});

export default router;
