<template>
  <a-layout style="min-height: 100vh">
    <!-- collapsed设置false 默认打开左侧菜单栏 -->
    <a-layout-sider
      v-model:collapsed="collapsed"
      :trigger="null"
      collapsible
      theme="light"
    >
      <div class="logo">
        <!-- <img src="../public/fxmes.png" /> -->
        <!-- 枫逍MES -->
      </div>
      <a-menu v-model:selectedKeys="selectedKeys" mode="inline" v-model:openKeys="openKeys">
        <a-menu-item key="1">
          <FunctionOutlined
            :style="{ fontSize: '30px' }"
            two-tone-color="#eb2f96"
          />

          <span style="font-size: 20px">
            <router-link to="/">{{ nickname }}</router-link>
          </span>
        </a-menu-item>
        <a-menu-item key="10">
          <span>
            <FolderOpenOutlined
              :style="{ fontSize: '20px' }"
              two-tone-color="#eb2f96"
            />
            <span> <router-link to="/FXCXGL1">程序管理</router-link></span>
          </span>
        </a-menu-item>

        <a-sub-menu key="sub2" >
          <template #title>
            <span>
              <PercentageOutlined
                :style="{ fontSize: '20px' }"
                two-tone-color="#eb2f96"
              />
              <span>宏程序</span>
            </span>
          </template>
          <a-menu-item key="3">
            <span>
              <router-link to="/FXHCX1">西门子系统(Siemens)</router-link></span
            >
          </a-menu-item>
          <a-menu-item key="31">
            <span>
              <router-link to="/FXHCX2">法兰克系统(Fanuc)</router-link></span
            >
          </a-menu-item>
          <a-menu-item key="32">
            <span>
              <router-link to="/FXHCX3">三菱系统(Mitsubishi)</router-link></span
            >
          </a-menu-item>
          <a-menu-item key="33">
            <span>
              <router-link to="/FXHCX4">广数系统980</router-link></span
            >
          </a-menu-item>
          <a-menu-item key="33">
            <span>
              <router-link to="/FXHCX4">其他系统(other)</router-link></span
            >
          </a-menu-item>
        </a-sub-menu>
        <!-- 工具箱起始位置 -->
        <a-sub-menu key="sub3">
          <template #title>
            <span>
              <ToolOutlined
                :style="{ fontSize: '20px' }"
                two-tone-color="#eb2f96"
              />
              <span>工具箱</span>
            </span>
          </template>

          <a-menu-item key="5">
            <span> <router-link to="/FXGJX1">切削参数</router-link></span>
          </a-menu-item>
          <a-menu-item key="8">
            <span> <router-link to="/FXGJX2">圆周孔位计算</router-link></span>
          </a-menu-item>
        </a-sub-menu>
        <!-- 工具箱结束位置 -->
        <!-- 设置起始位置 -->
        <a-sub-menu key="sub4">
          <template #title>
            <span>
              <SettingOutlined
                :style="{ fontSize: '20px' }"
                two-tone-color="#eb2f96"
              />
              <span>设置</span>
            </span>
          </template>

          <a-menu-item key="6">
            <span> <router-link to="/FxUser">系统设置</router-link></span>
          </a-menu-item>
          <a-menu-item key="7">
            <span> <router-link to="/FXSZ1">机床设置</router-link></span>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0; height: 50px">
        <a-row>
          <a-col :span="6">
            <MenuUnfoldOutlined
              v-if="collapsed"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
              :style="{ fontSize: '26px' }" />
            <MenuFoldOutlined
              v-else
              class="trigger"
              @click="() => (collapsed = !collapsed)"
              :style="{ fontSize: '26px' }"
          /></a-col>
          <a-col :span="17" style="font-size: 16px">
     
          </a-col>
          <a-col :span="1">
            <span v-if="token_zt == 1"
              ><router-link to="/FxLogin"
                ><UserOutlined />登陆</router-link
              ></span
            >
            <span v-if="token_zt == 2" @click="tuichu"
              ><router-link to="/">退出</router-link></span
            >
          </a-col>
        </a-row>
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          minHeight: '280px',
        }"
      >
        <!-- 内容展示区 -->
        <router-view />
      </a-layout-content>
      <!-- 尾部 -->
      <a-layout-footer :style="footerStyle">
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >蜀ICP备2022026530号-1
        </a>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
const token = window.localStorage.getItem("token");

import {
  // 这里需要引入图标名称
  MenuUnfoldOutlined,
  UserOutlined,
  MenuFoldOutlined,
  PercentageOutlined,
  FunctionOutlined,
  ToolOutlined,
  FolderOpenOutlined,
  SettingOutlined,
} from "@ant-design/icons-vue";

import { ref } from "vue";
import { Fxuser1 } from "./network/Index.js";
export default {
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingOutlined,
    PercentageOutlined,
    FunctionOutlined,
    UserOutlined,
    ToolOutlined,
    FolderOpenOutlined,
  },












  setup() {
    const openKeys=ref(['sub2','sub3','sub4']);
    const selectedKeys = ref(["1"]);
    const nickname = ref();
    const collapsed = ref(false);
    const token_zt = ref();
    if (token == null || token == 1) {
      token_zt.value = 1;
    } else {
      token_zt.value = 2;
    }
    Fxuser1().then((e) => {
      // console.log(e.data.data.nickname);
      nickname.value = e.data.data.nickname;
      if (nickname.value=="") {
        nickname.value="枫逍CNC"
      }
    });
    const tuichu = () => {
      window.localStorage.setItem("token", 1);
      location.reload();
    };

    return {
      selectedKeys,
      collapsed,
      nickname,
      token,
      token_zt,
      tuichu,
      openKeys,
    };
  },
};
</script>

<style>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.trigger {
  margin-left: 30px;
}
</style>
