// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// createApp(App).use(store).use(router).mount('#app')
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入ant - design - vue;
import Antd from "ant-design-vue";
// 引入css----reset.css
import "ant-design-vue/dist/reset.css";
// 添加.use(Antd)
createApp(App).use(store).use(router).use(Antd).mount("#app");