import axios from "axios";
export function request(config) {
  // const ip = "http://" + window.location.hostname + "/index.php/api/";
  // console.log(ip);
  const instance = axios.create({
    baseURL: "https://fxmes.cn/index.php/api/",
    // baseURL: ip,
    timeout: 5000000,
  });
  //请求拦截
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (err) => {
      console.log(err);
    }
  );
  //响应拦截
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      // 如果没有授权， 去login
      // 如果有错误， 在这里可以提示
      console.log(err);
    }
  );
  return instance(config);
}
